import "styles/pages/single-post.scss";

import React from "react";
import { graphql } from "gatsby";
import Layout from "components/Layout";
import Seo from "components/Seo";
import PostBanner from "components/PostBanner";
import TitleWithLine from "components/TitleWithLine";
import CaseStudyTile from "components/CaseStudyTile/CaseStudyTile";
import styled from "styled-components";
import theme from "../theme";

const CategoryBorder = styled.div`
  height: 3px;
  width: 100%;
  background-color: ${({ color }) => color};
`;

const CategoryTag = styled.div`
  background-color: ${({ color }) => color};
  padding: 0 30px 5px 20px;
  font-size: 14px;
  display: inline-block;
  color: ${theme.colors.white};
  border-radius: 0 0 16px 0;
`;

const SingleCaseStudy = ({ pageContext, data }) => {
  const caseStudies = data.caseStudyPosts.nodes;
  const cs = pageContext.post;
  return (
    <Layout>
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />
      <PostBanner
        title={cs.title}
        className="custom-post"
        date={cs.date}
        parentLink="/case-studies/"
      />
      <CategoryBorder
        color={
          cs.caseStudyCategories.nodes[0].caseStudyCategoryColor.categoryColor
        }
      />
      <div className="container">
        <CategoryTag
          color={
            cs.caseStudyCategories.nodes[0].caseStudyCategoryColor.categoryColor
          }
        >
          {cs.caseStudyCategories.nodes[0].name}
        </CategoryTag>
      </div>
      <section className="post case-study__post">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 first-col">
              <div
                className="post__content"
                dangerouslySetInnerHTML={{ __html: cs.content }}
              />
            </div>
            <div className="col-lg-6 second-col">
              <img
                src={cs.featuredImage?.node.sourceUrl}
                alt=""
                className="post__img"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="featured-news">
        <div className="container">
          <TitleWithLine variantX="left" variantY="bottom">
            Featured case studies
          </TitleWithLine>
          <div className="row section-news__row">
            {caseStudies?.map((nodes, index) => {
              const item = nodes;
              return (
                <div className="col-lg-4 case-study__tile" key={index}>
                  <CaseStudyTile
                    title={item.title}
                    desc={item.caseStudiesShortDesc.shortDescription}
                    thumbnail={item.featuredImage?.node.sourceUrl}
                    link={item.uri}
                    category={item.caseStudyCategories.nodes[0].name}
                    color={
                      item.caseStudyCategories.nodes[0].caseStudyCategoryColor
                        .categoryColor
                    }
                    isActive={true}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    caseStudyPosts: allWpCaseStudy(limit: 3) {
      nodes {
        content
        date(formatString: "D MMMM YYYY")
        featuredImage {
          node {
            sourceUrl
          }
        }
        slug
        seo {
          metaDesc
          title
        }
        title
        uri
        caseStudyCategories {
          nodes {
            name
            caseStudyCategoryColor {
              categoryColor
            }
          }
        }
        caseStudiesShortDesc {
          shortDescription
        }
        language {
          name
          locale
          code
          slug
        }
      }
    }
  }
`;

export default SingleCaseStudy;
