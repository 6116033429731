import React from "react";
import { Link } from "gatsby";
import theme from "../../theme";
import styled from "styled-components";

const CaseTile = styled.div`
  background-color: #fff;
  border-bottom: 2px solid $color-pink;
  transition: all 0.25s;
  box-shadow: none;
  border-radius: 8px;
  height: 100%;
  overflow: hidden;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${({ color }) => color};
    width: 0;
    height: 4px;
    transition: all 0.25s ease-in-out;
  }

  @media ${theme.screens.lgScreen} {
    margin-bottom: 60px;
  }

  ${theme.mixins.onEvent} {
    box-shadow: ${theme.shadows.shadow};
    &:after {
      width: 100%;
    }
  }
`;

const TextContainer = styled.div`
  padding: 18px 19px 70px;
  max-height: 267px;

  ${theme.screens.xlScreen} {
    max-height: 350px;
    min-height: 350px;
  }

  ${theme.screens.lgScreen} {
    min-height: unset;
    max-height: 300px;
  }

  ${theme.screens.mdScreen} {
    max-height: unset;
  }
`;

const Category = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0 5px 0 0;
  background-color: ${({ color }) => color};
  text-transform: uppercase;
  color: #fff;
  font-size: 10px;
  padding: 3px 50px 2px 15px;
  max-width: 130px;
`;

const Label = styled.div`
  color: ${theme.colors.pink};
  font-size: 14px;
  color: ${({ color }) => color};
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin: 10px 0 30px;

  ${theme.screens.xlScreen} {
    font-size: 16px;
    margin: 10px 0 20px;
  }
`;

const Thumbnail = styled.div`
  width: 100%;
  height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  background-image: url(${({ thumbnail }) => thumbnail});
`;

const Description = styled.div`
  font-size: 14px;
`;

const CaseStudyTile = ({ category, title, desc, link, thumbnail, color }) => {
  return (
    <Link to={link}>
      <CaseTile color={color}>
        <Thumbnail thumbnail={thumbnail}>
          <Category color={color}>{category}</Category>
        </Thumbnail>
        <TextContainer>
          <Label color={color}>Case study</Label>
          <Title>{title}</Title>
          <Description>{desc}</Description>
        </TextContainer>
      </CaseTile>
    </Link>
  );
};

export default CaseStudyTile;
